const DisclaimerPage = () => {
    return (
        <>
            <h5 className="text-center bg-dark p-3 text-white">DISCLAIMER</h5>
            <div className='pt-4 allFooterPage'>
                <p>If You Require Any More Information Or Have Any Questions About Our Site's Disclaimer, Please Feel Free To Contact Us By Email At Superfastking2018@Gmail.Com</p>

                <h3>DISCLAIMERS FOR SUPER FAST KING</h3>
                <p>All The Data On This Site - Superfastking.Com - Is Distributed Sincerely And For General Data Reason As it were. Really Quick Ruler Makes No Guarantees About The Fulfillment, Dependability And Exactness Of This Data. Any Move You Make Upon The Data You Track down On This Site (Quick Ruler), Is Rigorously Despite all advice to the contrary. Quick Lord Won't Be Responsible For Any Misfortunes As well as Harms Regarding The Utilization Of Our Site. Our Disclaimer Was Created With The Assistance Of The Disclaimer Generator And The Agreements Format.</p>

                <p>From Our Site, You Can Visit Different Sites By Following Hyperlinks To Such Outer Locales. While We Endeavor To Give Just Quality Connects To Valuable And Moral Sites, We Have Zero command Over The Substance And Nature Of These Locales. These Connections To Different Sites Don't Suggest A Proposal For Every one of the Substance Tracked down On These Locales. Site Proprietors And Content Might Change Without Notice And Might Happen Before We Have The Chance To Eliminate A Connection Which Might Have Turned sour'.</p>

                <p>Kindly Be Likewise Mindful That When You Leave Our Site, Different Destinations Might Have Different Security Approaches And Terms Which Are Outside of Our Reach. If it's not too much trouble, Make certain To Actually look at The Security Approaches Of These Destinations As Well As Their "Terms Of Administration" Prior to Taking part in Any Business Or Transferring Any Data.</p>

                <h3>CONSENT</h3>
                <p>By Utilizing Our Site, You Thus Agree To Our Disclaimer And Consent To Its Terms.</p>

                <h3>UPDATE</h3>
                <p>Would it be advisable for us We Update, Alter Or Roll out Any Improvements To This Report, Those Changes Will Be Unmistakably Posted Here.</p>
            </div>
        </>
    );
};

export default DisclaimerPage;




