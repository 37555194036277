import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import DayComponent from "./js/dayComponent";
import MonthTable from "./js/monthTable";
import DetailAccordian from "./js/detailAcord";
import SectionArtical from "./js/sectionArtical";
import FooterContentButton from "./js/footerContentBtn";
import FooterDrop from "./js/footerDrop";
import AllFooterButtonPage from "./js/allFooterButtonPages";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <div className="container-fluid">
      {/* <div> */}

      <App />

      <AllFooterButtonPage />
      <FooterContentButton />
    </div>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
