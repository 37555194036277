import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import AboutUsPage from './aboutUs';
import DisclaimerPage from './disclaimer';
import PrivacyPolicyPage from './privacy';
import FooterDrop from './footerDrop';
import DetailAccordian from './detailAcord';
import SectionArtical from './sectionArtical';

const AllFooterButtonPage = () => {
    const location = useLocation()
    const isAbout = location.pathname.includes('/about-us')
    const isDisclaimer = location.pathname.includes('/disclaimer')
    const isPrivacy = location.pathname.includes('/privacy-policy')
    return (
        <div className='pt-4'>
            {(!(isAbout || isDisclaimer || isPrivacy)) && (
                <>
                    <FooterDrop />
                    <DetailAccordian />
                    <SectionArtical />
                </>
            )

            }

            <Routes>
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/disclaimer" element={<DisclaimerPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>

            {/* <FooterButton /> */}
        </div>
    );
};

export default AllFooterButtonPage;
