import logo from "./logo.svg";
import banner from "./images/king-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import moment from "moment";
import DayComponent from "./js/dayComponent";
import "moment-timezone";
import { useLocation, useNavigation } from "react-router";
import trackVisitor from "./utilities/tracker";
import { Link } from "react-router-dom";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");
function App() {
  const location = useLocation();
  const isAbout = location.pathname.includes("/about-us");
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isPrivacy = location.pathname.includes("/privacy-policy");
  const date = new Date();
  const currentDate = moment(date).format("YYYY-MM-DD");
  const prevDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const currentTime = moment().format("HH:mm");

  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "WAIT",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);

  return (
    <>
      <div className="menubar">
        {/* seo setup start */}
        <Helmet>
          <title>Super Fast King</title>
          <meta name="description" content="Super Fast King, SuperFastKing" />
          <meta
            name="Keywords"
            content="sking, s king s-king, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
          />
          <link rel="canonical" href="https://superfastking.co/" />
        </Helmet>
        {/* seo setup end */}
        <div className="col-12">
          <div className="row">
            <div className="col-md-3 col-sm-12 menulink" value="MORNING START">
              <Link to="/MORNING_STAR">MORNING START</Link>
            </div>
            <div className="col-md-3 col-sm-12 menulink" value="SILVER CITY">
              <Link to="/SILVER_CITY">SILVER CITY</Link>
            </div>
            <div className="col-md-3 col-sm-12 menulink" value="LONDON BAZAR">
              <Link to="/LONDON_BAZAR">LONDON BAZAR</Link>
            </div>
            <div className="col-md-3 col-sm-12 menulink" value="DEV DARSHAN">
              <Link to="/DEV_DARSHAN">DEV DARSHAN</Link>
            </div>
          </div>
        </div>
        <div className="col-12 logomenu">
          <div className="row">
            <div className="col-6">
              <a href="/">
                <img className="col-6" src={banner} alt="bannerimage" />
              </a>
            </div>
          </div>
        </div>
        {!(isAbout || isDisclaimer || isPrivacy) && (
          <>
            <marquee>
              Super Fast King provide daily live satta result and chart of Satta
              King 2024, Satta King 2023, satta king fast, up satta king, satta
              king result, satta king chart, सट्टा किंग, Satta king Desawar ,
              Satta king Gali, Satta king Ghaziabad, Satta king Faridabad,Satta
              king Delhi Darbarand Satta King Taj, Old Taj Result, Delhi Bazar
              Result, Desawar Result, Shri Ganesh Result and More.
            </marquee>

            <div className="col-12 dayResult text-center pt-2">
              <h3>SUPER FAST KING LIVE RESULT</h3>
              <h6>SATTA KING | SATTA RESULT | SATTA RECORDS</h6>
            </div>
            <div className="result text-center mt-2">
              {datagame?.map((todayData, indexApp) => (
                <div key={indexApp} className="game">
                  <h3 className="mb-0">{todayData?.gameName}</h3>
                  <h5 className=" lh-1 blinking-text">
                    {todayData?.result || ""}
                  </h5>
                </div>
              ))}
            </div>
            <AdvertisementComponent type="odd" />
            <DayComponent dayGameData={data} />
          </>
        )}
      </div>
    </>
  );
}

export default App;
