import { Link } from "react-router-dom";

function FooterContentButton() {
  return (
    <div className="footerContent text-center">
      <div className="content">
        <h3>Where To Get A Fast Satta King Result?</h3>
        <p>
          At The Point When The Card Sharks Begin To Play The Game The
          Organization Confirms The Record Subtleties. On The Off Chance That
          You Concur With It, Just You Will Be Qualified To Play The Game. You
          Can Get The Satta Ruler Quick Outcome On The Site. Checking The
          Results Is Free. You Can Without Much Of A Stretch Really Look At
          Those. You Can Really Look At The Outcome From Any Gadget. You Can
          Really Take A Look At Either From A Portable Or From A PC. It Relies
          On You. In The Event That You Are The Fortunate Victor, You Will Get
          The Single Amount Measure Of Cash. Then Again, In The Event That You
          Have Lost The Game, You Need To Give The Punishment. You Need To Take
          Care Of The Organization. Maybe The Organization Charges The Cash From
          Your Record. There Are Heaps Of Dangers Related With The Game. In This
          Way, Pick Shrewdly And Play The Game. It Could Happen That You Became
          Rich Out Of Nowhere. The Game Can Change Your Karma Whenever Of The
          Day.
        </p>
      </div>
      <div className="content">
        <h3>चेतावनी (WARNING)</h3>
        <p>
          This Site Is Made For Diversion As It Were. This Site Advances No
          Wagering Action. Individuals Of The State In Which Wagering And
          Betting Are Restricted Ought To Obstruct Our Site. You Will Be
          Exclusively Liable For Any Benefit Or Misfortune.
        </p>
      </div>
      <div className="footerBtn">
        <div className="button-group ">
          <Link to="/" className="text-decoration-none text-white">
            <button className="col btn btn-info "> HOME</button>
          </Link>
          <Link
            to="/privacy-policy"
            className="text-decoration-none text-white"
          >
            <button className="col btn btn-info   "> PRIVACY POLICY</button>
          </Link>
          <Link to="/disclaimer" className="text-decoration-none text-white">
            <button className="col btn btn-info"> DISCLAIMER</button>
          </Link>
          <Link to="/about-us" className="text-decoration-none text-white">
            <button className="col btn btn-info">ABOUT US</button>
          </Link>
          <Link to="/sitemap" className="text-decoration-none text-white">
            <button className="col btn btn-info">SITEMAP</button>
          </Link>
        </div>
        <div className="copyright">
          <p>© 2021. All rights reserved. SUPER FAST SATTA KING</p>
        </div>
      </div>
    </div>
  );
}

export default FooterContentButton;
