// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.js'
// import 'bootstrap/dist/js/bootstrap.bundle.js'
// import 'bootstrap/dist/js/bootstrap.min.js'

function DetailAccordian() {
  return (
    <div className="detailAcord pt-5">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What are Types of the best Satta king games?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                There are no distinct types of Satta King games, so this is a
                misunderstanding. The various forms of Satta King are the only
                thing that distinguishes it from the names of various Indian
                cities or Gods. Gali Satta King, Faridabad Satta King, Delhi
                Satta King, Bazaar Satta King, Desawar Satta King, Kuber Satta
                King, JD Durga Satta King, New Faridabad Satta King, and Bhagya
                Rekha Satta King are some of the most popular Satta King games.
                In recent months, the number of people playing the Satta King
                game has increased. The popularity of some of these Satta King
                Games appears to be higher than that of others. The text has
                already mentioned the various types of Satta King games.
              </p>
              <p>
                The Satta King and a slew of other online games have benefited
                from the lockdown. It's not all about the money when people try
                to guess the Satta King numbers. As soon as new game results
                become available, we will be the first to bring them to you. The
                game operator was well aware that the Satta King Faridabad game
                had a significant impact on the Satta King's popularity. The
                most popular game is Satta King Desawar, in which people from
                all over the world wager large sums of money. The game is run
                out of Dubai and is considered one of the most reliable Satta
                games in which luck, rather than prediction, plays a big part.
              </p>
              <p>
                Even when they are not performing, they check the Satta King
                records charts on a regular basis to ensure that they are
                accurate. Satta King Gali, Satta King Desawar, Satta King
                Faridabad, Gaziyabad Satta King, Bhagya Rekha Satta King, and
                other popular Satta King games have different result
                announcement times. Due to its dependability and a slew of other
                positive features not found in other games, Satta King Faridabad
                is becoming increasingly popular. Many people have used our
                charts to develop winning strategies while playing the game.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              How to Play a Satta king game and win an amount ?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                The title of Satta King does not refer to the game; rather, it
                is used to honour the winner of the Satta. SattaKings'
                popularity in India, on the other hand, is growing by the day.
                Some people prefer to play Satta king on their computers, while
                others prefer to play it online. The person desires to become
                wealthy as quickly as possible. If you want to learn how to play
                Satta or learn more about Satta King, you should read this
                article because I will go over all of the different types of
                Satta as well as the advantages and disadvantages of Sattaking.
                Because in the past, a large number was placed in a pot and one
                number was drawn from it, Satta King is also known as Satta
                Matka.
              </p>
              <p>
                Satta King is a name you've most likely heard before. This is a
                well-known name in India. Satta King is a lottery-style game
                with numbers ranging from 00 to 99 that falls under the category
                of "Gambling." This game is known as Satta Matka, with Satta
                referring to betting or gambling and Matka referring to a pot
                from which numbers are drawn. Players bet on numbers ranging
                from 00 to 99 in the Satta Matka game. A number is drawn from
                the matka after all conditions have been met. He was dubbed the
                Satta King because he was always the winner when a person's
                number was called. Sattaking is a criminal offence in which two
                or more people choose a number based on their preferences. If a
                player's thinking number reaches zero, he wins the game and
                receives the entire prize pool. Checking for satta king fast
                results is now simple on our website.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Why is the Sattaking game Popular in India?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                The Satta King game has recently gained a lot of traction in the
                Indian market. The game is known as Satta Matka or Satta King
                because of this. The terms 'Satta' and 'Matka' refer to gambling
                or betting, as well as a pot from which a number is drawn.
                Because the numbers range from 00 to 99, they are referred to as
                gambling numbers. It's a lottery in which a number is drawn from
                a hat, and the person whose number is drawn receives the prize
                and is known as the "Satta King." Satta King was previously
                known as Satta Matka, a name with a large number of digits. The
                winner of the number was Satta Matka.
              </p>
              <p>
                A bet is made on a number between 00 and 99. After then, the
                player contacts the Khaiwal, who serves as a go-between for the
                players and the game operators. Every Khaiwal takes money from
                the players and transmits it to the corporation. After the
                winner is proclaimed, the Khaiwal collects the money from the
                corporation and distributes it to the winning bettor. The time
                for the random number to be opened is predetermined by the
                company. On the internet, you may quickly get the results on
                many websites that provide Satta results instantaneously online.
                You will be well compensated if you win the game. In a single
                night, winning the game can drastically change your life. The
                game will provide you with the triple sum. The players who win
                the game are known as the satta kings. The game is known as
                satta matka because it involves a pot. A random number is drawn
                from the pot to decide the outcome. If you're lucky, the number
                drawn determines whether or not you'll be a winner. Satta King
                is the name given to the game as a result of its growing
                popularity. You must deposit a minimum amount to play this money
                deposit game, which will be tripled if you win.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              How do you play Satta king?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse show"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Satta King is a simple game in which you have little control
                over the outcome. You must choose a number between 0 and 99 to
                make the simplification more natural. A Khaiwal is someone who
                acts as a go-between for gamblers and game operators. In the
                eyes of the Satta King, these Khaiwals are the most important
                people. These Khaiwal play an important role in collecting money
                from local players and delivering it to the company
              </p>
              <p>
                They also provide gamblers with all pertinent information and
                important game updates. He was the one who told the gambler
                about his good fortune after he correctly predicted the Satta
                King number. The number will be released at specific intervals.
                You are crowned Satta King when your number is drawn from the
                Satta Matka. The Khaiwal will collect the company's declared
                winning amount and send it to you via your preferred payment
                method. That's all there is to it. All you have to do now is
                locate a Khaiwal in your area, guess a random number between 0
                and 99, deposit the wager into Khaiwal's account, and wait for
                the outcome.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingfive">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsefive"
              aria-expanded="false"
              aria-controls="collapsefive"
            >
              How much profit can Satta King earn ?
            </button>
          </h2>
          <div
            id="collapsefive"
            className="accordion-collapse collapse show"
            aria-labelledby="headingfive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Calculating profit or lottery winnings is akin to daydreaming.
                We recommend that people refrain from engaging in such
                activities. So, to understand how much 90 times the betting
                amount is worth, let's look at an example of the smallest bet.
                Let's say you have a hundred rupees in your pocket and you learn
                about the Satta King Lottery. Your number popped up from the
                Satta Matka number withdrawal because you guessed the number and
                it was your lucky day. Then multiply 100 by 90 to get 9000
                Rupees. Users will receive 18000 rupees for 200 rupees, 27000
                rupees for 300 rupees, 36000 rupees for 400 rupees, and 45000
                rupees for 500 rupees, in a similar fashion.
              </p>
              <p>
                The user can bet as much money on one number as he wants and
                play as many numbers as he wants. Instead of betting all of his
                or her money on a single number, a good gambler employs strategy
                and selects a few numbers with a high probability of appearing.
                Gambling is a well-known fact that is the simplest way to make
                money. It is also illegal, and there are many more cases of
                people going bankrupt than succeeding. So, yes, you can become
                wealthy by playing Satta King, but the chances are slim and the
                risk factor is far too high.Yes, the Satta king game can make
                you wealthy. In addition, one of the most important factors in
                the game of gambling is luck.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              How to Find the Best Satta King Player ?
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse show"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Satta king is a modern online game that can be played all over
                the world. However, it has become a way of life in which one can
                make a lot of money. It is entirely dependent on your luck or
                fate. Satta Matka games are designed in such a way that anyone
                can understand and play the Kalyan Matka game without
                difficulty. Also, because Satta is a tool for making money, you
                can only manage your finances with it if you are capable of
                numerically calculating the various risks that are involved in
                the stakes, tips, and many tricks. Every Satta Player gambles to
                win the Satta King, but only a few succeed, which is why it is
                known as a "Game of Luck."Unless you have some patience, the
                entire Desawar game winning process is simple from start to
                finish. Satta King is a game that is based on some ideology and
                logic; all you have to do to become a Satta King is to
                thoroughly understand the basis drive by which your ideas can be
                sharpened, and you'll be a Satta King in no time! Satta king is
                a simple game to play because it is a game of chance. Before you
                begin playing this game, you must first learn a few simple
                steps. Satta king is a number-based game in which the outcome is
                entirely determined by the number that appears in the form of a
                result. To view the Satta results, you will find numerous
                websites.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailAccordian;
