const AboutUsPage = () => {
    return (
        <>
            <h5 className="text-center bg-dark p-3 text-white">ABOUT US</h5>
            <div className='pt-4 allFooterPage'>
                <p>Kindly Note That This Site Give Just Outcome. We Are Not Related With Any Organization.</p>

                <h3>USER EXPERIENCE MATTERS</h3>
                <p>Truth To Be Told, There Are A lot Of Surveying Administrations Around The Internet. We Attempt To Give The Best Involvement with Request The Satisfy You. In Each Step From Making Over Sharing To Assessment Of The Surveys, Really Quick Satta King Are Improving The Cycle Without any preparation. We're Situated In Hamburg, The Most Lovely City In India. Furthermore, Likely On the planet :)</p>

                <h3>HOW CAN I REACH OUT TO YOU GUYS?</h3>
                <p>You Can Always Write An E-Mail To Superfastking2018@Gmail.Com</p>
            </div>
        </>
    );
};

export default AboutUsPage;


