const PrivacyPolicyPage = () => {
    return (<>
        <h5 className="text-center bg-dark p-3 text-white">PRIVACY POLICY</h5>

        <div className='pt-4 allFooterPage'>
            <p>At Super Fast King, Accessible From Superfastking.Com, One Of Our Main Priorities Is The Privacy Of Our Visitors. This Privacy Policy Document Contains Types Of Information That Is Collected And Recorded By Super Fast King And How We Use It.If You Have Additional Questions Or Require More Information About Our Privacy Policy, Do Not Hesitate To Contact Us Through Email At Superfastking2018@Gmail.Com</p>

            <h3>LOG FILES</h3>
            <p>Quick Lord Keeps A Guideline Methodology Of Utilizing Log Records. These Records Log Guests When They Visit Sites. All Facilitating Organizations Do This And A Piece Of Facilitating Administrations' Investigation. The Data Gathered By Log Records Incorporate Web Convention (IP) Locations, Program Type, Web access Supplier (ISP), Date And Time Stamp, Alluding/Leave Pages, And Perhaps The Quantity Of Snaps. These Are Not Connected To Any Data That Is By and by Recognizable. The Reason for The Data Is For Breaking down Patterns, Managing The Webpage, Following Clients' Development On The Site, And Assembling Segment Data.</p>

            <h3>COOKIES AND WEB BEACONS</h3>
            <p>Like Some other Site, Really Quick Ruler Uses 'Treats'. These Treats Are Utilized To Store Data Including Guests' Inclinations, And The Pages On The Site That The Guest Got to Or Visited. The Data Is Utilized To Improve The Clients' Insight By Tweaking Our Page Content In light of Guests' Program Type As well as Other Data.</p>

            <h3>GOOGLE DOUBLECLICK DART COOKIE</h3>
            <p>Google Is One Of An Outsider Seller On Our Site. It Additionally Uses Treats, Known As DART Treats, To Serve Advertisements To Our Webpage Guests In view of Their Visit To Www.Website.Com And Different Destinations On The Web. Notwithstanding, Guests Might Decide To Decline The Utilization Of DART Treats By Visiting The Google Promotion And Content Organization Protection Strategy At The Accompanying URL � Https://Policies.Google.Com/Technologies/Ads</p>

            <h3>OUR ADVERTISING PARTNERS</h3>
            <p>Some Of Publicists On Our Webpage Might Utilize Treats And Web Guides. Our Promoting Accomplices Are Recorded Underneath. Every one Of Our Promoting Accomplices Has Their Own Protection Strategy For Their Arrangements On Client Information. For More straightforward Access, We Hyperlinked To Their Protection Arrangements Beneath.Google Https://Policies.Google.Com/Technologies/Ads</p>

            <h3>PRIVACY POLICIES</h3>
            <p>This Protection Strategy Applies Just To Our Web-based Exercises And Is Legitimate For Guests To Our Site With respect to The Data That They Shared And additionally Gather In Really Quick Lord. This Approach Isn't Relevant To Any Data Gathered Disconnected Or By means of Channels Other Than This Site.</p>

            <p>By Utilizing Our Site, You Thus Agree To Our Protection Strategy And Consent To Its Agreements.</p>

            <h3>THIRD PARTY PRIVACY POLICIES</h3>
            <p>Quick Lord's Security Strategy Doesn't Matter To Different Sponsors Or Sites. Hence, We Are Encouraging You To Counsel The Particular Protection Approaches Of These Outsider Promotion Servers For More Itemized Data. It Might Incorporate Their Practices And Directions About How To Quit Specific Choices. You Might Track down A Total Rundown Of These Protection Strategies And Their Connections Here: Security Strategy Connections. You Can Decide To Handicap Treats Through Your Singular Program Choices. To Know More Nitty gritty Data About Treat The board With Explicit Internet Browsers, It very well may Be Tracked down At The Programs' Separate Sites. What Are Treats?</p>

            <h3>CHILDREN'S INFORMATION</h3>
            <p>One more Piece Of Our Need Is Adding Assurance For Kids While Utilizing The Web. We Urge Guardians And Watchmen To Notice, Take part In, And additionally Screen And Guide Their Web-based Movement.</p>

            <p>Quick Lord Intentionally Gathers No Private Recognizable Data From Kids Younger than 13. Assuming You Think That Your Kid Gave This Sort Of Data On Our Site, We Firmly Urge You To Reach Us Right away And We Will Do Our Earnest attempts To Eliminate Such Data From Our Records speedily.</p>

            <h3>ONLINE PRIVACY POLICY ONLY</h3>
            <p>This Security Strategy Applies Just To Our Web-based Exercises And Is Substantial For Guests To Our Site With respect to The Data That They Shared And additionally Gather In Really Quick Lord. This Approach Isn't Pertinent To Any Data Gathered Disconnected Or By means of Channels Other Than This Site.</p>

            <h3>CONSENT</h3>
            <p>By Using Our Site, You Hence Consent To Our Assurance Procedure And Agree To Its Arrangements.</p>
        </div>
    </>
    );
};

export default PrivacyPolicyPage;



