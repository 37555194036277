import Select from "react-select";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useLocation } from "react-router-dom";
import MonthTable from "./monthTable";
import AdvertisementComponent from "../utilities/advertismentComponent";

let monthFullName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function FooterDrop() {
  const location = useLocation();

  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const curMonth = moment(date).format("MMMM");
  const numbermonth = monthFullName.indexOf(curMonth) + 1;
  const currYear = moment().tz("Asia/Kolkata").format("YYYY");

  const [dropValue, getDropValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selecYear, setSelectedYear] = useState({
    value: currentYear,
    label: currentYear,
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: numbermonth,
    label: monthFullName[numbermonth - 1],
  });
  const [sendList, sndList] = useState([]);
  const [selecteData, sendData] = useState([]);

  const gamePaths = {
    "/MORNING_STAR": "MORNING STAR",
    "/SILVER_CITY": "SILVER CITY",
    "/LONDON_BAZAR": "LONDON BAZAR",
    "/DEV_DARSHAN": "DEV DARSHAN",
  };

  useEffect(() => {
    const gameName = Object.keys(gamePaths).find((path) =>
      location.pathname.includes(path)
    );
    if (gameName) {
      const allData = {
        month: numbermonth,
        year: currYear,
        gameName: gamePaths[gameName],
        result: "",
        days: moment(`${currYear}-${numbermonth}`).daysInMonth(),
      };
      apiData(allData);
      setSelectedOption({
        value: gamePaths[gameName],
        label: gamePaths[gameName],
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchGameNames = async () => {
      try {
        const response = await fetch(
          "https://api.sattakingvip.co.in/getGameName",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );

        const json = await response.json();
        sndList(json);
      } catch (error) {
        console.error(error);
      }
    };

    fetchGameNames();
  }, []);

  useEffect(() => {
    if (selectedMonth && selecYear && selectedOption) {
      getAllGameDetails();
    }
  }, [selectedMonth, selecYear, selectedOption]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleChangeYear = (selecYear) => {
    setSelectedYear(selecYear);
  };

  const handleChangeMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "46px",
      height: "46px",
    }),
    input: (provided) => ({
      ...provided,
      minHeight: "46px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "46px",
      padding: "0 8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "46px",
      display: "flex",
      alignItems: "center",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "46px",
    }),
  };

  let monthArray = monthFullName.map((month, index) => ({
    value: index + 1,
    label: month,
  }));

  async function apiData(params) {
    try {
      const response = await fetch(
        "https://api.sattakingvip.co.in/getmonthdata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            month: params.month,
            year: params.year,
            gameName: params.gameName || "",
            result: "",
            days: params.days,
          }),
        }
      );

      const json = await response.json();
      sendData(json);
    } catch (error) {
      console.error(error);
    }
  }

  function getAllGameDetails() {
    if (selectedMonth && selecYear && selectedOption) {
      let allData = {
        month: selectedMonth.value,
        year: selecYear.value,
        gameName: selectedOption.value,
        result: "",
        days: moment(`${selecYear.value}-${selectedMonth.value}`).daysInMonth(),
      };

      if (
        selectedMonth.value > currentMonth &&
        currentYear == selecYear.value
      ) {
        toast.error("Selected month out of Date", {
          className: "toast-message",
        });
      } else {
        apiData(allData);
        getDropValue(allData);
      }
    } else {
      toast.error("All Fields Required", {
        className: "toast-message",
      });
    }
  }

  let arr = [];
  for (let year = currentYear - 9; year <= currentYear; year++) {
    arr.push({ value: year, label: year });
  }

  return (
    <div className="footer">
      <AdvertisementComponent type="random" />
      <MonthTable
        gamedata={selecteData}
        dropValue={dropValue}
        style={{ display: "block" }}
      />

      <div className="yellow-container pt-5">
        <div className="bottom-container">
          <div className="selection-container">
            <div className="col-12">
              <div className="row">
                <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                  <Select
                    menuPlacement="auto"
                    value={selectedMonth}
                    onChange={handleChangeMonth}
                    options={monthArray}
                    placeholder="Select Month"
                    styles={customStyles}
                  />
                </div>
                <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                  <Select
                    menuPlacement="auto"
                    value={selecYear}
                    onChange={handleChangeYear}
                    options={arr}
                    placeholder="Select Year"
                    styles={customStyles}
                  />
                </div>
                <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                  <Select
                    menuPlacement="auto"
                    value={selectedOption}
                    onChange={handleChange}
                    options={sendList}
                    placeholder="Select Game"
                    styles={customStyles}
                  />
                </div>
                <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                  <button
                    className="form-control"
                    id="go-button"
                    onClick={getAllGameDetails}
                  >
                    Go
                  </button>
                  <ToastContainer
                    autoClose={2000}
                    position="top-right"
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    pauseOnHover
                    draggable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdvertisementComponent type="even" />
    </div>
  );
}
export default FooterDrop;
